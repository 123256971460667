.weeghpanel {
    margin-top: -100px;
}

.chalkboard {
	background-image: url(/assets/images/chalkboard_texture.jpg);
	background-size: cover;
	font-size: 12pt;
	font-weight: bold;
	color: white;
	border: 0;
}

.chalkboard h1 {
	text-transform: uppercase;
}

.price_table {
	width: 100%;
}

.special_weegh_img {
	width: 100%; 
	height: 150px; 
	background-position: center center; 
	background-size: contain; 
	background-repeat: no-repeat;
}
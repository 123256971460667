.simonlogo {
    height: 100%;
}

.mysimonnav {

}

.divider-vertical {
height: 50px;
margin: 10px 9px;
border-left: 1px solid #F2F2F2;
border-right: 1px solid #FFF;
}

.loginBtn {
  background-color: #0259aa;
  color: white;
}

#navbar-type-top {
  height: 32px !important;
  min-height: 0;
  padding: 0 120px;
  margin: 0;
  margin-bottom: 50px; /* Required padding for .navbar-fixed-top. Remove if using .navbar-static-top. Change if height of navigation changes. */

}

.affix {
  top: 0px;
  width:100%;
  z-index: 10000;
}

.affix-top {
  margin: 0;
  top: 32px;
  z-index: 10000;
  position: absolute;
  width: 100%;
}

.navbar .nav {
     overflow: visible;
 }

#navbar-type-top.navbar-default .navbar-nav>li {
    color: #999;
    margin-top: 2px;
    background-color: white;
    padding: 4px 15px;
    margin-left: 5px;
    height: 28px;
    text-align: center;
}

#navbar-type-top.navbar-default .navbar-nav>li>a {
    color: #999;
    background-color: white;
    padding: 0;
    margin-left: 5px;
    height: 80%;
    text-align: center;
}

#navbar-type-top.navbar-default .navbar-nav>li>a:hover,
#navbar-type-top.navbar-default .navbar-nav>li>a:focus {
    color: black;
    background-color: white;
}

#navbar-type-top.navbar-default .navbar-nav>.active>a,
#navbar-type-top.navbar-default .navbar-nav>.active>a:hover,
#navbar-type-top.navbar-default .navbar-nav>.active>a:focus {
    color: black;
}

#navbar-type-top.navbar-default {
        font-family: 'Lato', sans-serif;
        font-size: 12px;
        background-color: #f2f2f2;
        background-image: url(/assets/images/gears.jpg);
        background-repeat: repeat-x;
        background-position: center center;
        font-weight: 400;
        color: white;
    }

#navbar-simon-top.navbar-default .navbar-brand {
    color: rgba(119, 119, 119, 1);
    padding: 6px;
    cursor: normal;
}

#navbar-simon-top.navbar-default .navbar-nav>li>a {
    color: rgba(79, 79, 79, 1);
    background-color: rgba(255, 255, 255, 1);
    padding: 22px;
}


#navbar-simon-top.navbar-default .navbar-nav>li>a:hover,
#navbar-simon-top.navbar-default .navbar-nav>li>a:focus {
    color: rgba(26, 26, 26, 1);
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 2px solid $simon-main; 

}
#navbar-simon-top.navbar-default .navbar-nav>.active>a,
#navbar-simon-top.navbar-default .navbar-nav>.active>a:hover,
#navbar-simon-top.navbar-default .navbar-nav>.active>a:focus {
    color: rgba(26, 26, 26, 1);
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 2px solid $simon-main;
}

#navbar-simon-top.navbar-default {
        border-top: 4px solid $simon-main; 
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 1);
        border-bottom-width: 1px;
        font-weight: 400;
        color: black;
    }

@media screen and (max-width: 1420px) {
    #navbar-simon-top.navbar-default .navbar-nav>li>a {
        padding: 10px 4px !important;
    }
    #navbar-simon-top.navbar-default {
        font-size: 13px;
    }
}

@media screen and (min-width: 992px) {
    #navbar-simon-top.navbar-default {
        height: 72px;
        padding: 0 90px;
    }
    #navbar-simon-top.navbar-default .navbar-brand {
        height: 65px;
        padding: 5px 0;
    }
    .simonlogo {
        height: 106px;
        content:url('/assets/images/logo_simon_overlay.png');
    }
}



@media screen and (max-width: 1420px) and (min-width: 768px) {
    .simonlogo {
        height: 100%;
        content:url('/assets/images/logo_simon.png');
    }
    #navbar-simon-top.navbar-default .navbar-brand {
        height: 60px;
    }
    
}

@media (max-width: 1250px) {
  .navbar-header {
      float: none;
  }
  .navbar-left,.navbar-right {
      float: none !important;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-fixed-top {
      top: 0;
      border-width: 0 0 1px;
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin-top: 7.5px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 2px;
      padding-bottom: 2px;
  }
  .collapse.in{
      display:block !important;
  }
  #navbar-type-top {
  padding: 0 30px;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 768px) {
    #navbar-simon-top.navbar-default .navbar-brand {
        height: 50px;
    }
    .simonlogo {
        height: 100%;
        content:url('/assets/images/logo_simon.png');
    }
}

#navbar-simon-top.navbar-default .navbar-toggle {
    border-color: black;
    color: black;
}
#navbar-simon-top.navbar-default .navbar-toggle:hover,
#navbar-simon-top.navbar-default .navbar-toggle:focus {
    background-color: white;
}
#navbar-simon-top.navbar-default .navbar-toggle .icon-bar {
    background-color: black;
}
#navbar-simon-top.navbar-default .navbar-toggle:hover .icon-bar,
#navbar-simon-top.navbar-default .navbar-toggle:focus .icon-bar {
    background-color: black;
}

#navbar-simon-top.navbar-default .navbar-nav>li>a {
    color: rgba(79, 79, 79, 1);
    background-color: rgba(255, 255, 255, 1);
    padding: 22px;
}

.dropdown-large {
  position: static !important;
}
.dropdown-menu-large {
  margin-left: 16px;
  margin-right: 16px;
  padding: 20px 0px;
  min-width: 400px;
  z-index: 200000;
}
.dropdown-menu-large > li > ul {
  padding: 0;
  margin: 0;
}
.dropdown-menu-large > li > ul > li {
  list-style: none;
}
.dropdown-menu-large > li > ul > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: normal;
  line-height: 1.428571429;
  color: #333333;
  white-space: normal;
}
.dropdown-menu-large > li ul > li > a:hover,
.dropdown-menu-large > li ul > li > a:focus {
  text-decoration: none;
  color: #262626;
  background-color: #f5f5f5;
}
.dropdown-menu-large .disabled > a,
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  color: #999999;
}
.dropdown-menu-large .disabled > a:hover,
.dropdown-menu-large .disabled > a:focus {
  text-decoration: none;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
  cursor: not-allowed;
}
.dropdown-menu-large .dropdown-header {
  color: $simon-main;
  font-size: 18px;
}
@media (max-width: 768px) {
  .dropdown-menu-large {
    margin-left: 0 ;
    margin-right: 0 ;
  }
  .dropdown-menu-large > li {
    margin-bottom: 30px;
  }
  .dropdown-menu-large > li:last-child {
    margin-bottom: 0;
  }
  .dropdown-menu-large .dropdown-header {
    padding: 3px 15px !important;
  }
}

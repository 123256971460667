.event-cell {
	padding: 10px;
}

@media screen and (min-width: 768px){
	.event-cell {
		height: 480px;
	}
}

.event-cell-img {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
	height: 130px;
}

.event-cell-title > h4{
	padding: 0;
	margin: 4px 0;
}

.event-cell-body {
	padding: 15px;
}

.subscribe_btn {
	background-color: $simon-main;
	padding: 3px 10px;
	color: white;
	font-weight: bold;
	height: 100%;
	text-transform: uppercase;
	text-align: center;
	cursor: pointer;
}

.filter_btn {
	padding: 10px;
	margin: 3px 6px;
	height: 30px;
	text-transform: uppercase;
	border-radius: 10px;
	border: 1px solid #999;
	color: #999;
	font-size: 12px;
	text-decoration: none;
	box-shadow: inset 0 1px 2px rgba(0,0,0,0.225), 0 1px 1px rgba(255,255,255,0.95);
}

.filter_btn:hover, 
.filter_btn:focus {
	color: black;
	background-color: #eee;
	text-decoration: none;
}

.filter_btn.active,
.filter_btn.active:hover,
.filter_btn.active:focus {
	color: black;
	background-color: #ddd;
}
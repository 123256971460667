.career-box {
	height: 250px;
	width: 450px;
	margin: 40px auto;
	padding: 10px 30px;
	color: white;
	
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: contain;
}

.career-box-white {
	height: 250px;
	width: 450px;
	margin: 40px auto;
	padding: 10px 30px;
	
	background-repeat: no-repeat;
	background-position: bottom right;
	background-size: contain;

	border: 1px solid #eee;

	background-color: white; 
	background-image: url(assets/images/career_wvd.png); 
}

.careerbtn {
	border-bottom: 1px solid #00856d;
	color: white;
	display: block;
	padding: 4px 0;
	margin: 5px 0;
	width: 100%;
}

.careerred {
	border-bottom: 1px solid #a8003a;
}

.careerwhite {
	color: #333;
	border-bottom: 1px solid #ddd;
}

.company-box {
	height: 220px;
	border: 1px solid #ddd;
	padding: 0;
}

.imgcontainer {
	background-repeat: no-repeat;
	background-position: center center;
	background-size: contain;
	background-origin: content-box;
}

.company_footer {
	height: 46px;
	width: 100%;
	padding: 3px 0 0 0;
}


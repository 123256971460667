
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

@font-face {
	font-family: "TheSans";
	src: url("/fonts/TheSans-B5Plain.ttf");
}
@font-face {
    font-family: "TheSans";
    src: url("/fonts/TheSans-B7Bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "Cafe";
    src: url("/fonts/cafe.otf");
}

@font-face {
    font-family: "Chalk";
    src: url("/fonts/chalk.ttf");
}

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.vcenter {
    display: flex;
    align-items: center;
}

// Import

@import "animate";
@import "navbar";
@import "carousel";
@import "simonevents";
@import "mysimon";
@import "simontemplate";
@import "eventslist";
@import "career";
@import "weegh";


.mySimonPanels .panel-heading {
    background-color: $simon-main;
    color: white;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.notification-item {
	cursor: pointer;
}

.notification-item.unread {
	background-color: #e7f0fc;
	color: #333 !important;
}

.notification-item:hover {
	background-color: #eee;
}

.panel-newsitem {
	border-color: #ddd;
}

.panel-newsitem .start { font-size: 1em; }
.panel-newsitem .started { font-size: 1em; }
.panel-newsitem .startedby { font-size: 1.2em; }
.panel-newsitem .visited { font-size: 1.2em; }
.panel-newsitem .visit { font-size: 1em; }
.panel-newsitem .post-title { font-size: 1.5em; text-decoration: none; color: #990099; }
.panel-newsitem .post-title:hover { text-decoration: none; color: #990099; }
/* Global Styles */

html,
body {
    height: 100%;
    background-image: url(/assets/images/pattern.png);
}

a {
  color: $simon-main;
}

@media screen and (min-width: 768px) {
    body {
    }   
}

h1,h2,h3,h4,h5 {
    font-family: 'TheSans', sans-serif;
    font-weight: bold;
}

.mastercontainer {
  min-height: 100vh;
}

.newssection {
}


.page-header {
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.img-portfolio {
    margin-bottom: 30px;
}

.img-hover:hover {
    opacity: 0.8;
}

.borderless td, .borderless th {
    border: none !important;
}

.sponsor_bar {
    background-color: white;
    height: 100px;
}

.firstpanels {
    margin-top: -250px;
}

.boardpanel {
    margin-top: -80px;
}

.panel-simon .panel-heading {
    background-color: $simon-main;
    color: white;
    font-weight: bold;
    font-family: 'OpenSans', sans-serif;
    box-shadow: inset 0 -1px 3px rgba(0,0,0,0.12), inset 0 -1px 2px rgba(0,0,0,0.24);
}

.firstpanels .panel-heading {
    background-color: rgba(255,255,255,0.9);
    color: black;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.firstpanels .panel-body {
    background-color: rgba(255,255,255,0.9);
    color: black;
    font-family: 'Lato', sans-serif;
}

.boardpanel .panel-heading {
    background-color: rgba(255,255,255,0.9);
    color: black;
    font-weight: bold;
    font-family: 'Lato', sans-serif;
}

.boardpanel .panel-body {
    background-color: rgba(255,255,255,0.9);
    color: black;
    font-family: 'Lato', sans-serif;
}

h5.cat_box {
    display: inline-block;
    border-radius: 5px;
    font-size: 12px;
    color: white;
    font-weight: bold;
    padding: 4px;
    margin-left: 6px;
    text-transform: uppercase;
}

h5.cat_box.activity {
    background-color: #4154dd;
}

h5.cat_box.board {
    background-color: red;
}

/* Footer Styles */
footer { 
    background-color:#f2f2f2; 
    background-image: url(assets/images/logo_simon_clootcrans.png);  
    background-position: left center;
    background-repeat: no-repeat;
    min-height:350px; 
    font-family: 'Open Sans',sans-serif; 
}
.footerleft { margin-top:50px; padding:0 36px; }
.logofooter { margin-bottom:10px; font-size:25px; color: #555; font-weight:700;}

.footerleft p { color:#555; font-size:12px !important; font-family: 'Open Sans', sans-serif; margin-bottom:15px;}
.footerleft p i { width:20px; color:#555;}


.paddingtop-bottom {  margin-top:50px;}
.footer-ul { list-style-type:none;  padding-left:0px; margin-left:2px;}
.footer-ul li { line-height:29px; font-size:12px;}
.footer-ul li a { color:#a0a3a4; transition: color 0.2s linear 0s, background 0.2s linear 0s; }
.footer-ul i { margin-right:10px;}
.footer-ul li a:hover {transition: color 0.2s linear 0s, background 0.2s linear 0s; color:#ff670f; }

.social:hover {
     -webkit-transform: scale(1.1);
     -moz-transform: scale(1.1);
     -o-transform: scale(1.1);
 }
 
 

 
 .icon-ul { list-style-type:none !important; margin:0px; padding:0px;}
 .icon-ul li { line-height:75px; width:100%; float:left;}
 .icon { float:left; margin-right:5px;}
 
 
 .copyright { min-height:40px; background-color:$simon-main;}
 .copyright p { text-align:left; color:#FFF; padding:10px 0; margin-bottom:0px;}
 .heading7 { font-size:21px; font-weight:700; color:#d9d6d6; margin-bottom:22px;}
 .post p { font-size:12px; color:#FFF; line-height:20px;}
 .post p span { display:block; color:#8f8f8f;}
 .bottom_ul { list-style-type:none; float:right; margin-bottom:0px;}
 .bottom_ul li { float:left; line-height:40px;}
 .bottom_ul li:before { content:"/"; color:#FFF; margin-right:8px; margin-left:8px;}
 .bottom_ul li a { color:#FFF;  font-size:12px;}


/* Responsive Styles */

@media(max-width:991px) {
    .customer-img,
    .img-related {
        margin-bottom: 30px;
    }
}

@media(max-width:767px) {
    .img-portfolio {
        margin-bottom: 15px;
    }

    header.carousel .carousel {
        height: 70%;
    }
}

.board-cell {
    padding: 10px;
}

.board-cell ul {
    list-style-type:none;
    padding: 10px;
}

.board-cell li {
    margin-left:8px;
}

@media screen and (min-width: 768px){
    .board-cell {
        height: 605px;
    }
}

.board-cell-img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 300px;
}

#slider-text{
  padding-top: 40px;
  display: block;
}
#slider-text .col-md-6{
  overflow: hidden;
}

#slider-text h2 {
  font-family: 'Josefin Sans', sans-serif;
  font-weight: 400;
  font-size: 30px;
  letter-spacing: 3px;
  margin: 30px auto;
  padding-left: 40px;
}
#slider-text h2::after{
  border-top: 2px solid #c7c7c7;
  content: "";
  position: absolute;
  bottom: 35px;
  width: 100%;
  }

#itemslider {
    background-color: white;
    border-bottom: 2px solid #ddd;
} 

#slider-control img{
  padding-top: 60%;
  margin: 0 auto;
}
@media screen and (max-width: 992px){
#slider-control img {
  padding-top: 70px;
  margin: 0 auto;
}
}

.carousel-showmanymoveone .carousel-control {
  width: 4%;
  background-image: none;
}
.carousel-showmanymoveone .carousel-control.left {
  margin-left: 5px;
}
.carousel-showmanymoveone .carousel-control.right {
  margin-right: 5px;
}
.carousel-showmanymoveone .cloneditem-1,
.carousel-showmanymoveone .cloneditem-2,
.carousel-showmanymoveone .cloneditem-3,
.carousel-showmanymoveone .cloneditem-4,
.carousel-showmanymoveone .cloneditem-5 {
  display: none;
}
@media all and (min-width: 768px) {
  .carousel-showmanymoveone .carousel-inner > .active.left,
  .carousel-showmanymoveone .carousel-inner > .prev {
    left: -50%;
  }
  .carousel-showmanymoveone .carousel-inner > .active.right,
  .carousel-showmanymoveone .carousel-inner > .next {
    left: 50%;
  }
  .carousel-showmanymoveone .carousel-inner > .left,
  .carousel-showmanymoveone .carousel-inner > .prev.right,
  .carousel-showmanymoveone .carousel-inner > .active {
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner .cloneditem-1 {
    display: block;
  }
}
@media all and (min-width: 768px) and (transform-3d), all and (min-width: 768px) and (-webkit-transform-3d) {
  .carousel-showmanymoveone .carousel-inner > .item.active.right,
  .carousel-showmanymoveone .carousel-inner > .item.next {
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.active.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev.right,
  .carousel-showmanymoveone .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}
@media all and (min-width: 992px) {
  .carousel-showmanymoveone .carousel-inner > .active.left,
  .carousel-showmanymoveone .carousel-inner > .prev {
    left: -16.666%;
  }
  .carousel-showmanymoveone .carousel-inner > .active.right,
  .carousel-showmanymoveone .carousel-inner > .next {
    left: 16.666%;
  }
  .carousel-showmanymoveone .carousel-inner > .left,
  .carousel-showmanymoveone .carousel-inner > .prev.right,
  .carousel-showmanymoveone .carousel-inner > .active {
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner .cloneditem-2,
  .carousel-showmanymoveone .carousel-inner .cloneditem-3,
  .carousel-showmanymoveone .carousel-inner .cloneditem-4,
  .carousel-showmanymoveone .carousel-inner .cloneditem-5,
  .carousel-showmanymoveone .carousel-inner .cloneditem-6  {
    display: block;
  }
}
@media all and (min-width: 992px) and (transform-3d), all and (min-width: 992px) and (-webkit-transform-3d) {
  .carousel-showmanymoveone .carousel-inner > .item.active.right,
  .carousel-showmanymoveone .carousel-inner > .item.next {
    -webkit-transform: translate3d(16.666%, 0, 0);
    transform: translate3d(16.666%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.active.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev {
    -webkit-transform: translate3d(-16.666%, 0, 0);
    transform: translate3d(-16.666%, 0, 0);
    left: 0;
  }
  .carousel-showmanymoveone .carousel-inner > .item.left,
  .carousel-showmanymoveone .carousel-inner > .item.prev.right,
  .carousel-showmanymoveone .carousel-inner > .item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

.carousel-item-div {
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 130px;
    cursor: pointer;
}


.btn-simon { 
  color: #FFFFFF; 
  background-color: #F47321; 
  border-color: #F47321; 
} 
 
.btn-simon:hover, 
.btn-simon:focus, 
.btn-simon:active, 
.btn-simon.active, 
.open .dropdown-toggle.btn-simon { 
  color: #FFFFFF; 
  background-color: #E3681C; 
  border-color: #F47321; 
} 
 
.btn-simon:active, 
.btn-simon.active, 
.open .dropdown-toggle.btn-simon { 
  background-image: none; 
} 
 
.btn-simon.disabled, 
.btn-simon[disabled], 
fieldset[disabled] .btn-simon, 
.btn-simon.disabled:hover, 
.btn-simon[disabled]:hover, 
fieldset[disabled] .btn-simon:hover, 
.btn-simon.disabled:focus, 
.btn-simon[disabled]:focus, 
fieldset[disabled] .btn-simon:focus, 
.btn-simon.disabled:active, 
.btn-simon[disabled]:active, 
fieldset[disabled] .btn-simon:active, 
.btn-simon.disabled.active, 
.btn-simon[disabled].active, 
fieldset[disabled] .btn-simon.active { 
  background-color: #F47321; 
  border-color: #F47321; 
} 
 
.btn-simon .badge { 
  color: #F47321; 
  background-color: #FFFFFF; 
}

